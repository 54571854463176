.dashboard,
.page-content {
  .loading {
    min-width: 280px;
    white-space: nowrap;
    text-align: center;
    transform: translate(-50%, -50%);
    //margin-left: 35px;

    .lds-ellipsis {
      margin-top: -30px;
    }
  }

  .projectview {
    .loading {
      margin-left: -110px;
    }
  }
}

.widget-logo {
  position: absolute;
  right: 10px;

  top: 5px;
}

.dashboard-smart-container {
  padding: 30px 0 !important;
  max-width: 1300px;
  .row {
    justify-content: center;
  }

  .row > div {
    flex: 0 0 350px;
    max-width: 350px;
    margin: 0 10px 20px 10px !important;
    background: #ffffff;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    padding-bottom: 20px;

    .loader-container {
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 0;
      transform: translate(-50%, -50%);
    }

    @media screen and (max-width: 991px) {
      flex: 0 0 320px;
      max-width: 320px;
    }

    .card {
      background: none;
      box-shadow: none;
      border-radius: 0;
      padding-left: 0;
      padding-right: 0;
      border: 0;
      min-height: 0;
      position: static;
      margin-bottom: 0;
      .card-header {
        padding-right: 100px;
        padding-left: 0;
        position: relative;
        padding-top: 15px;
        background-color: transparent;
        border-bottom: 1px solid #eaeaea;
        .color-circle {
          width: 44px;
          height: 44px;
          text-align: center;
          border-radius: 50%;
          margin-right: 15px;
          position: relative;
          i {
            position: relative;
            top: 1px;
            left: -1px;
            font-size: 20px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            margin: 0;
          }
        }

        > span {
          font-weight: bold;
          font-size: 15px;
        }

        .toMapButton {
          position: absolute;
          right: 20px;
          span {
            color: #65baf1;
            font-size: 12px;
            font-weight: normal;
          }
        }
      }

      .card-footer {
        position: absolute;
        border: 0;
        left: 0;
        right: 0;
        bottom: 25px;
        display: none;
      }

      .card-body {
        .normal-widget-content {
          padding-top: 10px;
          justify-content: space-between;
          width: 100%;

          > .divider {
            //display: none !important;
            height: auto;
            margin: 5px 0 0 0;
            padding: 0;
            border-bottom: 1px solid #eaeaea;
            text-align: center;
            height: 0;

            span {
              display: inline-block;
              background-color: white;
              padding: 0 6px;
              position: relative;
              top: 0px;
              color: #999;
            }
          }

          > .icon-value:not(.icon-value-multiple) {
            border: 0;
            width: 90px;
            height: 110px;
            background-color: white;
            border-radius: 50%;
            flex: 0 1 90px;
            margin: 0 0 10px 0;
            position: relative;
            transition: 0.2s all ease;

            .display {
              display: block;
              text-align: center;
            }

            > span {
              color: #7c7c7c;
              font-size: 12px;
              padding: 4px 10px;
              text-align: center;
              line-height: 20px;
              min-width: 80px;
              white-space: nowrap;
            }

            .icon {
              display: block;
              margin: 3px 0 0 0;
              color: #cccccc !important;
              transition: 0.2s all ease;
            }

            .value {
              display: block;
              font-size: 18px;
              margin-top: -4px;
            }
          }

          > .icon-value-multiple {
            padding-top: 10px;
            padding-bottom: 10px;
            width: 100%;
            flex: 0 1 100%;
            position: relative;
            border: 0;

            .value-item {
              margin: 0 20px;

              i.icon {
                color: #cccccc !important;
              }

              .value {
                font-size: 17px !important;
                padding-left: 6px;
              }
            }

            > span {
              color: #7c7c7c;
              font-size: 12px;
              padding: 4px 10px;
              text-align: center;
              line-height: 20px;
              min-width: 80px;
              white-space: nowrap;
            }
          }

          .simple-value-unit {
            border: 0;
            border: 0;
            width: 100px;
            height: auto;
            background-color: white;
            border-radius: 50%;
            flex: 0 1 100px;
            margin: 20px 0 10px 0;
            position: relative;
            transition: 0.2s all ease;
            justify-content: flex-start !important;

            .display {
              text-align: center;
              display: block;
              padding: 5px 0 0;
              position: relative;

              .value {
                text-align: right !important;
                display: inline-block;
                padding-right: 2px;
                font-size: 15px !important;
              }

              .unit {
                text-align: left !important;
                width: auto;
                display: inline-block;
                padding-left: 2px;
                font-size: 15px;
              }

              > span {
                color: #7c7c7c;
                font-size: 12px;
                border-radius: 20px;
                padding: 4px 10px;
                text-align: center;
                line-height: 18px;
                min-width: 80px;
                display: inline-block;
              }
            }
          }

          .double-icon {
            position: relative;
            padding-bottom: 10px;
            border-bottom: 1px solid #eaeaea;

            .display {
              i.icon,
              i.icon-right {
                color: #cccccc !important;
                transition: 0.2s all ease;
              }

              i.icon {
                margin-right: 10px;
              }

              i.icon-right {
                margin-left: 10px;
              }

              .value {
                font-size: 17px !important;
                padding-left: 6px;
                padding: 0 10px;
              }
            }
            > span {
              color: #7c7c7c;
              font-size: 12px;
              padding: 4px 10px;
              padding-bottom: 5px;
              text-align: center;
              line-height: 20px;
              min-width: 80px;
              white-space: nowrap;
              transition: 0.2s all ease;
            }

            &:hover {
              > span {
                opacity: 1;
                visibility: visible;
              }
            }
          }

          .chart-value {
            border: 0;
            padding-top: 0;
            width: 100%;

            .display {
              padding: 10px 10px 0 10px !important;
              canvas {
                width: 100%;
              }
            }

            > span {
              color: #999;
              display: block;
              padding-top: 5px;
            }
          }

          .vertical-progress {
            border: 0;
            padding-left: 12px;
            margin-top: 0;

            &.border {
              border: 0 !important;
              // border-top: 1px solid #eaeaea !important;

              > div {
                padding-top: 25px;
              }
            }

            > div {
              padding-top: 15px;
              > span {
                float: left;
                width: 64px;
                height: 64px;
                background-color: #f1f1f1;
                border-radius: 50%;
                text-align: center;
                color: #939393;
                font-weight: bold;
                line-height: 64px;
                font-size: 15px !important;
              }

              .display {
                padding: 0;
                display: block;
                float: left;
                width: calc(100% - 78px);
                margin-left: 14px;
                text-align: left;

                .icon {
                  display: none;
                }

                .value,
                .unit {
                  display: inline-block;
                  vertical-align: middle;
                  font-weight: 400;
                  font-size: 15px !important;
                  width: auto;
                  line-height: 64px;
                }
              }
            }

            .progress {
              display: none;
            }
          }

          .horizontal-progress {
            border: 0;
            padding-left: 0;
            padding-right: 0;
            margin-top: 0;
            position: relative;
            margin-bottom: 25px;

            > div {
              padding-left: 10px;
              padding-right: 10px;
              width: 100%;
            }

            &.last {
              border: 0 !important;
              > div {
                border-left: 1px solid #eaeaea !important;
              }
            }

            &.border {
              border: 0 !important;
              border-top: 1px solid #eaeaea !important;
              > div {
                margin-top: 25px;
              }
            }

            &.last.border {
              border-top: 1px solid #eaeaea !important;
            }

            .display {
              padding: 7px 10px;
              background-color: white;
              border-radius: 25px;
              transition: 0.2s all ease;

              .label-container {
                padding-left: 1px;
                .icon {
                  color: #cccccc !important;
                  font-size: 30px !important;
                  line-height: 1;
                }

                > span {
                  position: absolute;
                  bottom: -25px;
                  color: #7c7c7c;
                  font-size: 12px;
                  padding: 4px 10px;
                  text-align: center;
                  line-height: 20px;
                  min-width: 57px;
                  white-space: nowrap;
                }
              }

              .value-container {
                font-size: 16px !important;
                padding-right: 2px !important;
              }
            }

            .progress {
              display: none;
            }
          }
        }
      }
    }

    .box-environment {
      .card {
        .card-body {
          .normal-widget-content {
            > .icon-value {
              &:hover {
                .icon {
                  color: #ca8dee !important;
                }
              }
            }
          }
        }
      }
    }

    &.box-light {
      .card {
        .card-header {
          .color-circle {
            i {
              font-size: 22px;
            }
          }
        }
        .card-body {
          .normal-widget-content {
            > .divider:first-child {
              border-bottom: 0;
              margin-top: 0;

              span {
                top: -11px;
              }
            }

            > .icon-value {
              &:hover {
                .icon {
                  color: #ffd573 !important;
                }
              }
            }
          }
        }
      }
    }

    &.box-wifi {
      .card {
        .card-body {
          .double-icon {
            padding-bottom: 15px;
            > .display {
              &:hover {
                .icon,
                .icon-right {
                  color: #83f4bc !important;
                }
              }
            }

            & + .chart-value {
              position: relative;
              padding-top: 10px;
              width: 100%;

              &:after {
                content: "";
                width: 48px;
                height: 48px;
                position: absolute;
                top: -24px;
                left: 50%;
                margin-left: -24px;
                background-size: 100% auto;
                background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAD2UlEQVRoge2ZV2vjQBCAx47Te68kgYSQQp7y/39CXhNSCaT33ouPb+8EOm3RSDbnM/iDxfFKETt9Ri6Uy+Wy1DHFej68NAT4D2gIUGvqXoCStVMBb29vcn9/Lx8fH/L19WVWsViUUqkkLS0t0tXVJd3d3f+XABzy4uJCbm9vjQBpIFBfX58MDQ05hUF4hNWSu5B9f3/L+fm5OTx/56G1tVWmp6elp6fH/Pf+/r60t7fL+Pi4+mm5LICmt7e35fPz07qWhff3d9nZ2ZGBgQEpFArGilmVkVmAp6cn2d3dza11Fzc3N389PwuZstDDw4NsbW1V9fBJfn5+5PX11dr3obYAboOPptHZ2WmyDf7NImg5FNkJF9G4HVYgFjSoghiNb2xsmAzhg6wyMTEhzc3Nnjt+gxWPj4/l5eXFuhYxODgos7Oz1r4LlQVOTk68h29qapK5uTlnSnRBxrm6ugoKkCUOUgXA5KRKF7jHwsKCdHR0OK7aYOy9vT3jTiHITtQXCmAaqUF8enpq7UXMz8+rDw+kysnJSZmZmTFu0tbWZt0TobVCUEQ0dn19be3D2NhYrraA4GQRM0B8cVjW8/OzWQQ936nYFQlAwPGwJLjO6OiotZ8HYqi3t9esCOJDWySDAvh8lcoZ90+0RX1IgoWIkfX1dXNlbW3NfCa/J8nilsEY8BUUjWkrgbijVdHEQdACPjNSrOJQuOLa9FkkC4+Pj2bx7BBBAVy5n0yiSW+VQDeq7UhLZBoO5YJg1fQ9Wo1Hvp8GiovmgrTZoOg7PLjaAgT2uVa1IHWjEF8KjxP0BQRwTVnsuYSLsk6SZLZJswRaJ01XXAeolARSEtJrtWfbOFRpLUEBaLwuLy+tfQYQOk9iJA7CxrXLPVnGQ/mTug8ODowQIyMj1vUkwTqAAK4YIQbOzs6sfS1Yz2dBqjBLOzSlzgOMj76KvLS0lKlqaokOT5uRRtACQPfog9aYtrda4Dq07hxcc3jRCEDn6AsqcvXm5qa35cgCz7q7uzPDU5aZWzVSomVGylD+Hx4eNkGbp0pHRSsqYGntQxz1iy1tte3v7zfdKimYod6VBOKgdVyRGdhn6RBqdaGVqakpOTo6sq7F4c0DKwLNrq6uWvfhJvg5SYDPvK9qMtmb6kjuPzw8lEp+WsOaaJ08T51AQG3QJsn8eh1fX1xcdLYSIXAVAh4fJzGgAIZ3UaZLH7l+H8DsKysrRpg0H48gU1GgqNYceHl5Wf3uJ0TFP7NyKF5UMT+7iGIgT4b5JwLEid4uoG3cg64VbbuCuFo0fuiuNQ0Bak1DgFpT3wKIyC+0rRPE4oeKxwAAAABJRU5ErkJggg==");
              }
            }
          }
        }
      }
    }

    &.box-totem {
      .card {
        .card-body {
          padding-top: 5px;
          .double-icon {
            padding-top: 20px;
            margin-bottom: 20px;
            > .display {
              &:hover {
                .icon,
                .icon-right {
                  color: #2b7154 !important;
                }
              }
            }
          }
          .normal-widget-content {
            > .icon-value {
              &:hover {
                .icon {
                  color: #2b7154 !important;
                }
              }
            }
          }
        }
      }
    }

    &.box-parking {
      .card {
        .card-body {
          .normal-widget-content {
            > .icon-value {
              &:hover {
                .icon {
                  color: #5072b7 !important;
                }
              }
            }
          }
        }
      }
    }

    &.box-traffic {
      .card {
        .card-body {
          .normal-widget-content {
            > .icon-value {
              &.half {
                margin-left: 30px;

                &.last {
                  margin-left: 0;
                  margin-right: 30px;
                }
              }
              &:hover {
                .icon {
                  color: #67ced9 !important;
                }
              }
            }

            .horizontal-progress {
              &:hover {
                .display {
                  .icon {
                    color: #67ced9 !important;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.box-project {
      .card {
        .card-body {
          .normal-widget-content {
            padding: 0 25px;
            @media screen and (max-width: 991px) {
              padding: 0 10px;
            }
            > .icon-value {
              &:hover {
                .icon {
                  color: #c3b388 !important;
                }
              }
            }

            .horizontal-progress {
              margin-top: 40px;

              &:hover {
                .display {
                  .icon {
                    color: #c3b388 !important;
                  }
                }
              }

              &.last {
                > div {
                  border: 0 !important;
                }
              }

              &.full {
                display: none;
              }
            }

            .image-value {
              display: none;
            }
          }
        }
      }
    }

    &.box-last-image {
      padding-bottom: 0;
      .card {
        padding-bottom: 0;
        margin-bottom: 0;
        .card-header,
        .card-footer {
          display: none;
        }
        .card-body {
          .normal-widget-content {
            width: 100%;

            .horizontal-progress {
              display: none;

              &.full {
                display: block;
                width: 100%;

                .display {
                  flex-direction: column;
                  background-color: transparent !important;
                }

                .label-container {
                  padding: 0;
                  width: 100%;
                  padding-top: 40px;
                  text-align: center;
                  flex: 0 0 100%;
                  .icon {
                    display: none;
                  }

                  span {
                    display: block;
                    visibility: visible;
                    opacity: 1;
                    position: static;
                    transform: translateX(0);
                    background-color: transparent !important;
                    color: #a0a0a0;
                    width: 100%;
                    padding: 0;
                    text-align: center;

                    &:before {
                      display: none;
                    }
                  }
                }

                .value-container {
                  padding: 0;
                  width: 100%;
                  flex: 0 0 100%;
                  text-align: center;

                  div {
                    text-align: center;
                    padding-bottom: 40px;
                    padding-top: 5px;
                    font-weight: bold;
                  }
                }
              }
            }

            .image-value {
              margin-left: -15px;
              margin-right: -15px;
              margin-top: 18px;
              margin-bottom: 18px;
              width: calc(100% + 30px);
              height: 300px;
              border-radius: 8px 8px 8px 8px;
              transform: rotate(90deg) translateX(-30px);
            }
          }
        }
      }
    }
  }
}
.dashboard-project-image {
  transform: rotate(90deg) scaleY(1.2) translateY(50px);
  margin-top: 80px;
  margin-bottom: 80px;
  margin-left: 60px;
  height: auto;
  width: 100%;
}
.card-footer {
  position: relative;
}

.box-item {
  .card-header,
  .normal-widget-content {
    cursor: pointer;
  }
}

.toMapButton {
  text-align: left;
}

.expanded-content {
  background-color: #f9f9f9;
  margin: 15px;
  .nav-tabs {
    width: 100%;
    background-color: white;
  }
}

.sub-header {
  z-index: 7;
  height: 115px;
  background: linear-gradient(
    to bottom,
    rgba(250, 250, 250, 1) 78%,
    rgba(250, 250, 250, 0) 100%
  );
  position: fixed;
  width: 100%;
  top: 75px;

  .full {
    max-width: inherit;
  }

  .sub-header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    ul {
      position: relative;
      left: -250px;

      @media screen and (max-width: 1120px) {
        left: -100px;
      }
    }
    select {
      font-weight: 700;
      font-size: 19px;
      text-transform: uppercase;
      border: 0px;
      -webkit-appearance: select;
      min-width: 200px;
      outline: none;
      cursor: pointer;
    }
  }
}

.index {
  float: right;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;

  @media screen and (max-width: 991px) {
    display: none;
  }

  li {
    margin-left: 10px;
    list-style: none;
    line-height: 20px;
    border-right: 1px dotted #d5d5d5;
    padding-right: 15px;
    i {
      font-size: 17px;
      margin-right: 15px;
    }

    span {
      display: inline-block;
      position: relative;
      font-size: 12px;
      line-height: 20px;
      top: -3px;
    }
  }

  li:last-child {
    border-right: 0px dotted #d5d5d5;
  }
}

.expanded-content {
  height: auto !important;
}

.tab-pane {
  width: 100%;
}
.expanded-wrapper {
  height: auto;

  .chart-value {
    height: 230px;
    align-items: flex-start !important;
    margin: 10px;
    padding-left: 20px;
    canvas {
      height: 180px !important;
    }
  }

  .nav {
    width: 100%;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 14px;
    a {
      color: #222222;
      outline: none !important;
    }
  }

  .tab-content {
    margin-left: 10px;
    margin-right: 10px;
    background: #fafafa;
    margin-bottom: 10px;
    height: 90%;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}

.dashboard-container {
  padding: 54px 63px 54px 63px;
  margin-bottom: 30px;
  max-width: 1400px;
  margin: 0 auto;
  @media screen and (max-width: 550px) {
    padding: 54px 0px 0 0;
  }
}

.dashboard-card-body {
  min-height: 370px;
  background: #ffffff;
  border-radius: 8px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.dashboard-activate {
  background-color: #FF785D;
  color: #FFFFFF;
  padding: 8px 10px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  border-radius: 4px;
  box-shadow: 3px 3px 6px rgb(0 0 0 / 60%);
  cursor: pointer;

  &:hover {
    box-shadow: 1px 1px 1px rgb(0 0 0 / 40%);
  }
}

.dashboard-activate-requested {
  filter: grayscale(1);
  background-color: #FF785D;
  color: #FFFFFF;
  padding: 8px 10px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  border-radius: 4px;
  cursor: not-allowed;
}

.dashboard-card-detail {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 0;
  @media screen and (min-height: 800px) {
    font-size: 15px;
  }
}

.dashboard-card-header-detail {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #444444;
  margin-bottom: 10px;
  @media screen and (min-height: 800px) {
    font-size: 24px;
    line-height: 30px;
  }
}

.dashboard-card-header {
  padding-right: 10px;
  position: relative;

  .dashboard-header-col {
    margin-top: 10px;
    position: relative;

    .dashboard-card-title {
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 34px;
      color: #444444;
      margin-bottom: 0;
      min-height: 70px;
    }

    .dashboard-card-type {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #444444;
      margin-bottom: 5px;
      margin-top: 40px;
    }

    .dashboard-card-desc {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #444444;
      margin-bottom: 0;
      width: 100%;
      @media screen and (max-width: 550px) {
        width: 100%;
      }
      .logo-list {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .imageplace {
          margin-right: 12px;
        }
        .more-points {
          color: #444444;
          padding-top: 0px;
          font-size: 20px;
          letter-spacing: 2px;
          margin-left: 20px;
          margin-top: -10px;
        }
      }
      .logo-list.bottom-with-border {
        border-top: 1px dashed #979797;
        margin-top: 35px;
        padding-top: 30px;
      }
    }

    .icon {
      width: 60px;
      height: 60px;
      background-color: white;
      border-radius: 50%;
      position: absolute;
      left: 15px;
      svg {
        width: 24px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &.icon-smart {
        background-color: lighten(#ae7bc0, 30%);
        svg {
          color: #ae7bc0;
        }
      }

      &.icon-list {
        background-color: lighten(#7abebf, 30%);
        svg {
          width: 20px;
          color: #7abebf;
        }
      }

      &.icon-user {
        background-color: lighten(#bf987b, 30%);
        svg {
          color: #bf987b;
        }
      }

      &.icon-link {
        background-color: lighten(#b8bf7a, 30%);
        svg {
          color: #b8bf7a;
        }
      }

      &.icon-city {
        background-color: lighten(#bf817a, 30%);
        svg {
          width: 22px;
          color: #bf817a;
        }
      }

      &.icon-settings {
        background-color: lighten(#7ba2bf, 30%);
        svg {
          color: #7ba2bf;
        }
      }
    }
  }

  .dashboard-card-button {
    display: block;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    position: absolute;
    background: #F06640;
    top: 10px;
    right: 15px;
    color: #444444;
    text-align: center;
    padding-top: 0px;
    font-size: 20px;
    letter-spacing: 2px;
    padding-left: 2px;
    @media screen and (min-height: 800px) {
      display: block;
    }
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.dashboard-card-inside {
  padding: 20px 30px;
  position: relative;

  @media screen and (max-width: 479px) {
    padding: 20px;
  }
}

.dashboard-card-body-paragraph {
  padding: 0 30px 0 30px;
  @media screen and (min-height: 800px) {
    padding: 15px 30px;
  }
  p {
    font-size: 14px;
  }
}

.dashboard-icons {
  display: none;
}

.dashboard-card-footer {
  padding: 0 0 15px 0;
}

.dashboard-card-footer-details {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 17px;
  color: #444444;
  text-align: left;
  .col {
    flex: 0 0 33%;
    max-width: 33%;
    @media screen and (max-width: 599px) {
      flex: 0 0 30%;
      max-width: 50%;
      font-size: 16px;
    }
  }

  > .col-4 {
    @media screen and (max-width: 479px) {
      padding: 0;
      flex: 0 0 28%;
    }
  }

  .footer-details-answer {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #444444;
    margin-top: 10px;
  }
}

.dashboard-card-footer-header {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #444444;
  text-align: left;
  .col {
    flex: 0 0 33%;
    max-width: 33%;
    @media screen and (max-width: 599px) {
      flex: 0 0 30%;
      max-width: 50%;
      font-size: 11px;
    }
  }

  > .col-4 {
    @media screen and (max-width: 479px) {
      flex: 0 0 28%;
	  word-break: break-word;
	  padding: 0 5px;
    }
  }
}
.dasboard-container-row {

  .inactive {
    &:hover {
      box-shadow: none !important;
      transition: none !important;
    }
  }

  .inactive-card {
    filter: contrast(0.5);

    &:hover {
      box-shadow: none !important;
      transition: none !important;
      cursor: not-allowed !important;
    }
    > div {
      filter: grayscale(1);
    }
  }
}

.moduless {
  background: white;
  padding: 10px 20px;
  border-radius: 30px;
}

.dasboard-container-row {
  display: flex;
  > div {
    flex: 0 0 calc(33.3% - 30px);
    max-width: calc(33.3% - 30px);
    margin: 0 15px 30px 15px !important;
    background: #ffffff;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
    padding-left: 0;
    padding-right: 0;
    border-radius: 8px;

    @media screen and (max-width: 1199px) {
      flex: 0 0 calc(100% - 30px);
      max-width: calc(100% - 30px);
    }
    &:hover {
      box-shadow: 0px 2px 20px rgba(153, 215, 216, 0.8);
      transition: 0.2s all ease;
      cursor: pointer;
    }
  }
}

.dashboard-card-button:hover {
  background-color: #a1d9da;
  box-shadow: inset 1px 2px 3px #949494;
}

//Dashboard SMART
.dashboard-smart-search {
  padding: 5px 20px 5px 20px;
  background-color: white;
  margin-bottom: 10px;
  width: 280px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.0874945);
}

.dashboard-smart-search-input {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #9a9a9a;
  border: 0;
}

.dashboard-smart-search-icon {
  padding-right: 20px;
}

// Dashboard kataszter
.dashboard-kataszter-main-conatiner {
  padding: 30px 0 30px 0 !important;
  max-width: 1280px;
  margin: 20px auto 0 auto;
  align-items: flex-start;

  @media screen and (max-width: 991px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .kataszter-accordion {
    > .row {
      margin: 0;
    }

    .kataszter-accordion-title {
      background: #ffffff;
      padding: 0 20px 0 20px;
      line-height: 1;
      margin: 0 10px 15px 10px;
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
      display: block;
      position: relative;
      @media screen and (max-width: 479px) {
        margin-left: 0;
        margin-right: 0;
      }
      @media screen and (max-width: 330px) {
        padding-left: 10px;
      }

      h2 {
        min-height: 70px;
        display: flex;
        margin: 0;
        align-items: center;
        font-size: 0;
        @media screen and (max-width: 991px) {
          flex-direction: column;
          width: auto;
          align-items: flex-start;
          padding-top: 15px;
        }
        span {
          font-size: 18px;
          line-height: 1;
          text-transform: uppercase;
          color: #444;
          font-weight: 400;
          @media screen and (max-width: 991px) {
            padding-bottom: 5px;
          }
          @media screen and (max-width: 479px) {
            font-size: 17px;
          }

          &:first-child {
            font-weight: 600;
            width: 25%;
            min-width: 280px;
          }

          &.color-circle {
            width: 46px;
            height: 46px;
            background-color: #a9a9a9;
            position: absolute;
            top: 50%;
            right: 15px;
            border-radius: 50%;
            cursor: pointer;
            transition: 0.2s all ease;
            transform: translateY(-50%);
            @media screen and (max-width: 479px) {
              width: 40px;
              height: 40px;
              top: 35px;
            }
            @media screen and (max-width: 320px) {
              right: 10px;
            }

            svg {
              color: white;
              font-size: 26px;
              position: absolute;
              top: 52%;
              left: 50%;
              transform: translate(-50%, -50%);
              @media screen and (max-width: 479px) {
                font-size: 22px;
              }
            }

            &:hover {
              background-color: #65baf1;
            }
          }
        }

        input {
          font-size: 16px;
          margin-left: 80px;
          border-radius: 8px;
          padding: 8px 12px;
          font-weight: 600;
          border: 2px solid #999;
          color: #666;
          @media screen and (max-width: 991px) {
            margin-left: 0;
            margin: 10px 0 15px 0;
          }
          &:hover,
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }
    }
  }

  .dashboard-kataszter-container.add-new-kataszter {
    border: 3px dashed #979797;
    background: transparent;
    &:hover {
      border: 3px dashed #FF785D;
    }
    .dashboard-add-kataszter-button {
      svg {
        color: #FF785D;
        // &:hover {
        //   color: #FF785D!important;
        // }
      }
    }
    // &:hover {
    //   span {
    //     color: #FF785D!important;
    //   }
    // }
  }

  .dashboard-kataszter-container {
    flex: 0 0 calc(33.332% - 20px);
    max-width: calc(33.332% - 20px);
    margin: 0 10px 20px 10px !important;
    background: #ffffff;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
    padding-left: 0;
    padding-right: 0;
    position: relative;
    padding-bottom: 20px;
    align-self: flex-start;

    @media screen and (max-width: 991px) {
      flex: 0 0 calc(50% - 20px);
      max-width: calc(50% - 20px);
    }

    @media screen and (max-width: 559px) {
      flex: 0 0 calc(100% - 20px);
      max-width: calc(100% - 20px);
    }

    @media screen and (max-width: 479px) {
      flex: 0 0 calc(100%);
      max-width: calc(100%);
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    .dashboard-kataszter-container-header-row {
      margin-left: 0;
      margin-right: 0;
      padding: 0 10px;
      background-color: #83F4BC;
    }

    .card-header {
      padding-left: 0;
      position: relative;
      padding-top: 5px;
      padding-bottom: 5px;
      background-color: transparent;
      border-bottom: 1px solid #eaeaea;
      display: flex;
      .color-circle {
        width: 44px;
        height: 44px;
        min-width: 44px;
        text-align: center;
        border-radius: 50%;
        margin-right: 15px;
        position: relative;
        margin-left: 0;
        background-color: transparent;
        svg {
          position: relative;
          top: 1px;
          left: -1px;
          font-size: 20px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          margin: 0;
        }
      }

      > span {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
      }

      .kataszter-button {
        position: absolute;
        right: 0;
        color: #65baf1;
        padding: 0;
        font-size: 12px;
        font-weight: normal;
        border: 0;
        background-color: transparent;
        &:focus {
          outline: 0 !important;
        }
      }
    }

    .dashboard-kataszter-details {
      padding-top: 25px;
      padding-left: 15px;
      padding-right: 15px;
      .col {
        text-align: left;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        padding: 0 15px;
        color: #444444;
        @media screen and (max-width: 767px) {
          font-size: 15px;
        }
        p {
          margin: 0 0 5px 0;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #000000;

        }
        span {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
        }
      }

      .details-button {
        text-align: right;
        .kataszter-button {
          width: 92px;
          height: 24px;
          background: #C4C4C4;
          border-radius: 33px;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #FFFFFF;
          border: 0;
          margin-top: 15px;
          &:hover {
            transition: 0.2s all ease;
            background-color: #FF785D;
          }
        }
      }
    }

    .dashboard-kataszter-group {
      flex-direction: column;
      .col {
        padding: 20px 0 0 0;
        text-align: left;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #444444;
        border-top: 1px solid #eaeaea;
        margin: 0 15px;
        margin-top: 20px;
        width: auto;
        max-width: 100%;

        > p {
          margin-bottom: 12px;
          font-size: 15px;
          font-weight: 600;
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0 0 0 30px;
          li {
            display: flex;
            padding: 7px 0;
            p {
              font-size: 14px;
              line-height: 14px;
              font-weight: 400;
              margin-bottom: 0;

              & + span.button {
                margin-left: auto;
              }
            }

            span.button {
              font-size: 18px;
              cursor: pointer;
              transition: 300ms all ease 0s;

              &:hover {
                color: #65baf2;
              }
              & + span.button {
                margin-left: 10px;
              }
            }
          }
        }
      }

      .dashboard-kataszter-toggle {
        background-color: #a9a9a9;
        border-radius: 0px 0px 8px 8px;
        display: block;
        position: relative;
        height: auto;
        margin: 20px 0 -20px 0 !important;
        padding: 15px 0;
        cursor: pointer;

        svg {
          font-size: 22px;
          color: white;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:hover {
          transition: 0.2s all ease;
          background-color: #65baf1;
        }
      }
    }

    &.kataszter-button-container {
      padding: 0 !important;
      position: relative;
      height: 160px;
      text-align: center;

      .dashboard-add-kataszter-button {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        cursor: pointer;

        i {
          font-style: normal;
        }

        &:hover {
          svg {
            color: #444;
          }
          span {
            color: #444;
          }
        }

        svg {
          width: 60px;
          height: 60px;
          color: #a9a9a9;
          transition: 0.2s all ease;
        }

        span {
          display: block;
          color: #a9a9a9;
          font-size: 13px;
          padding-top: 10px;
          transition: 0.2s all ease;
        }
      }
    }
  }
}

// Dashboard modal
.dashboard-input-modal {
  border: 0;
  border-bottom: 2px solid #9c9c9c;
  width: 100%;
}

.dashboard-input-modal:focus {
  outline-width: 0;
  background-color: #f0f0f0;
  border-bottom: 2px solid #9c9c9c;
  border-radius: 3px;
}

.request-send-button {
  border: 0;
  width: 100%;
  height: 50px;
  background-color: #2ed47a;
  color: white;
  border-radius: 3px;
}

.request-text-area {
  color: white;
  background-color: #dfdfdf;
}

.file-drop {
  width: 100%;
  padding: 20px 20px 20px 20px;
  margin: 20px 20px 20px 20px;

  background-color: #2ed47a;
}

.kataszter-validate-header {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;

  color: #000000;
}

.kataszter-validate-body {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}

// Dashboard connection
.dashboard-connection-container {
  .dasboard-container-row {
    > div {
      flex: 0 0 calc(33.3% - 30px);
      max-width: calc(33.3% - 30px);

      @media screen and (max-width: 1199px) {
        flex: 0 0 calc(50% - 30px);
        max-width: calc(50% - 30px);
      }

      @media screen and (max-width: 850px) {
        flex: 0 0 calc(100% - 30px);
        max-width: calc(100% - 30px);
      }

      a {
        text-decoration: none;
      }

      .dashboard-card-header {
        padding-bottom: 0;

        &:after {
          display: none;
        }
      }

      .dashboard-header-col {
        padding-left: 20px;
        padding-right: 0;

        .icon {
          display: none;
        }
      }

      .dashboard-card-footer {
        display: none;
      }

      .dashboard-card-desc {
        max-width: 300px;
        .img {
          height: 72px;

          img {
            height: auto;
            width: 180px;
            margin-bottom: 0;
            margin-top: 0;
          }
        }
      }

      &.col-new-connection {
        padding-top: 40px;
        .dashboard-outside-text {
          margin-bottom: 0;
        }
        .dashboard-card-desc img {
          margin-left: -30px;
          margin-top: 20px;
        }
        .dashboard-card-button {
          display: none;
        }

        .footer-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          align-content: flex-end;
          align-items: end;
          padding: 40px 20px 0 20px;
          @media screen and (max-width: 479px) {
            padding: 20px 31px 20px 31px;
          }
          p {
            margin-bottom: 0;
          }
        }

        span.plus {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          display: block;
          position: absolute;
          top: 5px;
          right: 15px;

          &:before {
            position: absolute;
            content: "";
            width: 3px;
            height: 33px;
            background-color: #f4f4f4;
            left: 50%;
            margin-left: -1.5px;
            top: 6px;
          }

          &:after {
            position: absolute;
            content: "";
            width: 33px;
            height: 3px;
            background-color: #f4f4f4;
            top: 50%;
            left: 6px;
            margin-top: -1.5px;
          }
        }
        span.plus.red {
          background-color: #F06640;
        }
        span.plus.green {
          background-color: #82EF7B;
        }
      }
    }
    .thirdparty-tile {
      min-height: 410px;
      .imageplace {
        min-height: 186px;
      }
    }
    .col-kaptalan {
      padding: 20px 31px 0 31px;
      @media screen and (max-width: 479px) {
        padding: 20px 31px 20px 31px;
      }
      .imageplace {
        text-align: center;
      }
      .thirdparty-description {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #444444;
        display: flex;
        height: 110px;
        align-content: flex-end;
        align-items: flex-end;
      }
      .footer-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-content: flex-end;
        align-items: end;
        padding-bottom: 10px;
        padding-top: 20px;
        p {
          margin-bottom: 0;
        }
      }
    }
  }
}

.favourite-cadastres {
  h4 {
    font-size: 18px;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 400;
    color: white;
    @media screen and (max-width: 991px) {
      padding-bottom: 5px;
    }
    @media screen and (max-width: 479px) {
      font-size: 17px;
    }
    padding: 10px;
    font-weight: 500;
  }

  p {
    font-size: 14px;
    padding-left: 30px;
    color: white;
    font-weight: 200;
  }
}

.widget-device-selecter {
  font-size: 14px;
  margin: 2px;
  .form-label {
    font-weight: 500 !important;
  }

  select {
    width: 11em !important;
  }
}

.totem-alert {

  width: 100%;
  font-size: 13px;
  background-color: rgb(247, 108, 108);
  padding: 10px;
  border-radius: 5px;

  .totem-alert-p {
    display: inline-block;
    margin-bottom: 0px;
  }

  button {
    float: right;
    border: none;
    background-color: rgb(255, 155, 155);
    color: white;
    border-radius: 10px;
  }

  button:hover {
    background-color: white;
    color: rgb(255, 155, 155);
  }
}

.totem-alert-modal {
  .modal-title {
    width: 100%;
  }
  
  .table {
    font-size: 13px;
  }

  button {
    width: 100%;
    padding: 10px;
    background-color: rgb(151, 151, 151);
    border-radius: 10px;
    color: white;
    border: none;
  }

  button:hover {
    color: rgb(153, 153, 153);
    background-color: rgb(230, 230, 230);
  }
}


.box-valid-date{
  position: absolute;
  top: 64px;
  left: 3px;
  background: white;
  font-size: 10px;
  color: rgb(206, 206, 206);
}