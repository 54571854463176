// Kataszter sub container / table
.dashboard-kataszter-sub-conatiner {

	.kataszter-view {
		margin-right: 0;
		background: white;

		.pagination {
			padding-top: 15px;
		}

		.items-per-page {
			margin-top: 50px;

			@media screen and (max-width: 991px) {
				margin-top: 0px;
			}

			select {
				padding: 6px 12px;
				border: 1px solid $borderChildColor !important;
				border-radius: 0;
				color: $baseColor;
				font-size: 13px;
			}

			span {
				font-size: 13px;
				padding-right: 10px;
			}
		}
		
		.pages {
			font-size: 13px;
			margin-top: 42px;

			@media screen and (max-width: 991px) {
				margin-top: 25px;
			}
		}
	}
	
	.kataszter-table-view {
		margin-right: 0;
		background-color: white;
		padding: 5px 20px 0 20px;

		@media screen and (max-width: 991px) {
			overflow-x: scroll;
		}

		table.table {
			margin-bottom: 0;
		}
	}

	table {
		border-bottom: 0 !important;
		tr {
			// th, td {
			// 	word-break: break-all;
			// }

			&:last-child {
				td {
					border-bottom: 0 !important;
				}
			}
		}

		tbody {
			tr[role="row"] {
				td {
					background: none !important;
				}
			}
		}

		input {
			height: 20%;
		}

		select {
			height: 50%;
		}
		
		.acceptbuttons {
			margin-left: auto;
			.btn-primary {
				margin-right: 12px;
			}
		}
	}

	.kataszter-filter {
		background-color: white;
		padding-top: 15px;
    	overflow: auto;
		padding-right: 4px;

		@media screen and (max-width: 991px) {
			overflow: visible;
		}

		p {
			i {
				font-size: 16px;
				padding-right: 7px;
			}
			
			font-weight: 500;
			font-size: 15px;
		}

		input {
			box-shadow: none;
			border: none !important;
		}

		select {
			font-size: 12px;
		}
	}

	.kataszter-top {
		padding-top: 40px;

		&:after {
			clear: both;
			display: table;
			content: '';
		}

		.nav {
			float: left;

			.back-button {
				padding-left: 15px;

				i {
					font-size: 12px;
					padding-right: 5px;
					position: relative;
					top: -1px;
				}
			}
		}

		p {
			float: left;
			margin-bottom: 0;
			line-height: 48px;
			font-size: 20px;
			font-weight: 600;
			position: relative;
			top: -2px;
		}
	}
}


.filters-container-map {

	max-height: 200px;
	overflow: auto;
	
	> div {
		margin-top: 15px;

		label {
			font-weight: 100 !important;
			font-size: 13px;
			margin-bottom: 4px;
		}

		input {
			border: 1px solid #e9e9e9;
			border-radius: 0px;
			font-size: 13px;
			background-color: white;
			box-shadow: none;
			width: 100%;
			height: 33px;
			font-weight: 500;
			padding: 0 15px;
			&:focus {
				outline: none;
				box-shadow: none;
			}
		}

		select {
			outline: none;
			height: 33px;
			border-radius: 0px !important;
			-webkit-appearance: none;
			-moz-appearance: none;
			line-height: 14px;
			background-position: right 50%;
			background-repeat: no-repeat;
			background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
			box-shadow: inset 0 1px 8px 0 rgba(0, 0, 0, 0.07);
			border: 1px solid #e9e9e9;
			font-size: 13px;
			background-color: white;
			box-shadow: none;
			width: 100%;
		}
	}
}

.kataszter-table-view {
	border-right: 1px solid #e9e9e9;
	.infotable-wrapper {
		.table-wrapper {
			margin-left: 0;
			column-count: 1 !important;
			-moz-column-count: 1 !important;
			-webkit-column-count: 1 !important;

			table {
				border-collapse: collapse;
			}

			tbody {
				display: flex;
				flex-wrap: wrap;
				flex-flow: space-between;
				align-content: flex-start;
				height: auto;
				align-items: stretch;
				overflow: auto;

				tr {
					flex-basis: 32%;
					width: 32%;
					display: flex;
					align-items: center;
					border-top: 1px dotted #BBB;

					@media screen and (max-width: 1599px) {
						flex-basis: 48%;
						width: 48%;
					}

					@media screen and (max-width: 991px) {
						flex-basis: 100%;
						width: 100%;
					}

					&:nth-child(3n+2) {
						margin-left: 2%;
						margin-right: 2%;

						@media screen and (max-width: 1599px) {
							margin-left: 0%;
							margin-right: 0%;
						}
					}

					&:nth-child(2n+1) {
						@media screen and (max-width: 1599px) {
							margin-right: 4%;
						}

						@media screen and (max-width: 991px) {
							margin-right: 0;
						}
					}

					&:nth-child(2n) {
						
					}

					td {
						height: auto;
						line-height: 18px;
						padding-top: 10px !important;
						padding-bottom: 10px !important;
						width: 60%;
						background-color: transparent !important;

						&:first-child {
							font-weight: 500 !important;
							width: 40%;
							padding-left: 0 !important;

							&::first-letter {
								text-transform: uppercase;
							}
						}

						&:last-child {
							word-break: break-all;
							padding-right: 0 !important;
						}
					}
				}
			}

			.edit-table {
				tr {
					td {
						padding-top: 6px !important;
						padding-bottom: 6px !important;

						input, select {
							height: 32px !important;
							position: static;
							font-size: 12px !important;
							font-weight: normal;
							padding-left: 8px;
						}
					}
				}
			}
		}
	}
}

.file-container {

	padding: 10px;
	margin-left: 10px;

	.cadastre-uploaded-file {
		font-size: 12px;
		margin-bottom: 5px;
		color: rgb(77, 174, 242);
		font-family: 'Montserrat', sans-serif
	}

	.remove-warning {
		width: 100%;
		background-color: #e3e3e3;
		border-radius: 10px;
		padding: 5px !important;
		margin: 10px 0;

        > button:first-child { color: red; }
        padding: 0;
        text-align: center;
        p {
            margin: 5px;
        }
        button {
            width: auto;
            border-radius: 5px;
            border: 0;
            box-shadow: none;
            color: white;
            font-weight: 400;
            text-shadow: none;
            font-size: 14px;
            font-family: "Montserrat", sans-serif;
            font-size: 12px;
            margin: 5px;
            padding:5px 10px 5px 10px
        }
        .yes {
            
			background-color: #d17373;
        }

        .no {            
			background-color: #7dd173;
        }
    }
}

.upload-file-container .row{
	height: 80% !important;
}

.cadastre-to-map-button {
	background-color: #8a8a8a;
	border-radius: 10px;
	width: auto;
	border-radius: 5px;
	border: 0;
	box-shadow: none;
	color: white;
	font-weight: 400;
	text-shadow: none;
	font-size: 14px;
	font-family: "Montserrat", sans-serif;
	font-size: 12px;
	padding:5px 10px 5px 10px;
	margin-right: 17%;
}
.cadastre-to-map-button:hover {
	background-color: #bdbdbd;
	color: rgb(95, 95, 95);
}
