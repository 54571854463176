.navbar .city-name {
    font-family: Montserrat;
    font-size: 14px;
    color: #444444;
    font-weight: bold;
    font-style: normal;
    text-transform: uppercase;
    text-align: center;
}
.navbar .navbar-brand-block {
    border-right: 1px dashed #B6AFAF;
    padding-right: 1rem;
    margin-right: 1rem;
    padding-top: 0.4rem !important;
    padding-bottom: 0.3rem !important;

    @media screen and (max-width: 767px) {
        border-right: 0;
        margin: 0 auto;
        display: block;
        clear: both;
        width: 100%;
        margin-right: 0 !important;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-right: 0;
    }
}

.navbar-brand {
    margin-right: 0 !important;
    padding-top: 0.4rem !important;
    padding-bottom: 0.3rem !important;

    @media screen and (max-width: 767px) {
        border-right: 0;
        margin: 0 auto;
        display: block;
        clear: both;
        width: 100%;
        margin-right: 0 !important;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
    }
} 

.mobile-menu-opener {
    width: 25px;
    height: 20px;
    position: fixed;
    margin: 0;
    transform: rotate(0deg);
    transition: .2s ease-in-out;
    cursor: pointer;
    top: 23px;
    left: 20px;
    z-index: 1000;

    @media screen and (min-width: 768px) {
        display: none;
    }
    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background-color: #a9a9a9;
        border-radius: 3px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
        &:nth-child(1) {
            top: 0px;
            transform-origin: left center;
        }

        &:nth-child(2) {
            top: 8px;
            transform-origin: left center;
        }

        &:nth-child(3) {
            top: 16px;
            transform-origin: left center;
        }
    }

    &.active {
        span {
            &:nth-child(1) {
                transform: rotate(45deg);
                top: 0px;
                left: 4px;
                background-color: white;
            }

            &:nth-child(2) {
                width: 0%;
                opacity: 0;
                background-color: white;
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
                top: 18px;
                left: 4px;
                background-color: white;
            }
        }
    }
}

.divider {
    border-left: 1px solid #d8d8d8;
    height: 40px;
    margin-top: 20px;
}

.header-page-heading {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #444444;
    margin: 20px 20px 20px 20px;
    @media screen and (max-width: 991px) {
        margin: 20px 0;
    }

    @media screen and (max-width: 767px) {
        display: none;
    }
}

.alert-message {
    background: red;
    width: 5px;
    height: 4px;
    position: absolute;
    bottom: -1px;
    right: 39px;
    padding: 3px 7px 10px 5px;
    border-radius: 11px;
    color: white;
    margin-bottom: 14px;
    font-size: 10px;
}
