.modeChange {
	
	margin-right: 25px;

	label {
		text-align: center;
		font-size:12px;
		display: block;
	}

	@media screen and (max-width: 979px) {
		display: none;
	}


}
#mode-change.btn-group {
	button {
		border-radius: 0px !important;
		height: 26px;
	    line-height: 15px;
	    font-size: 11px;
	    min-width: 116px;
	    max-width: 116px;
		&.active {
			background-color: #2CD02A !important;
			color: white !important;
		}

	}
}

.navbar {
	height: 76px;
	background: white;
	box-shadow: 0 3px 6px 0 rgba(0,0,0,0.06);
	padding-left: 0px !important;
	padding-right: 0px !important;

	&.fixed-top {
		z-index: 999 !important;
	}

	@media screen and (max-width: 990px) {
		padding-top: 0px !important;
	}

	@media screen and (max-width: 767px) {
		height: auto;
	}

	.user .badge {
		width: 16px;
    	height: 16px !important;
    	font-size: 9px;
    	padding: 0px !important;
    	line-height: 16px !important;
    	min-width: auto;
    	position: absolute;
		bottom: 13px;
		left: 50%;
		margin-left: -20px;
		z-index: 10;
		color: white !important;
	}

	.user span {
		margin-top: 5px;
	}

	.navbar-nav {
		flex-direction: row;
		align-items: center;

		@media screen and (max-width: 767px) {
			width: 100%;
			margin: 0 0 5px 0;
			justify-content: space-around;
			border-top: 1px solid #f4f4f4;
			padding: 10px 15% 0 0;
    	}

		.nav-item {
			position: relative;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			display: flex;
			flex-direction: column;
			color: #a9a9a9;
			line-height: 1;
			height: 45px;
			transition: 0.2s all ease;
			padding: 0 20px;

			i, span {
				color: #a9a9a9;
				transition: 0.2s all ease;
			}

			&.active,
			&:hover {
				color: #181818;
					i, span {
					color: #181818;
				}
			}

			i {
				svg {
					font-size: 1.75rem !important;
					margin-bottom: 7px;

					@media screen and (max-width: 767px) {
						font-size: 1.5rem !important;
					}
				}
			}

			&.user {
				svg {
					font-size: 1.6375rem !important;
					position: relative;
					top: 4px;
					@media screen and (max-width: 767px) {
						font-size: 1.375rem !important;
					}
				}
				span {
					white-space: nowrap;
				}

				@media screen and (max-width: 767px) {
					position: relative;
					top: -2px;
				}
				img{
					height: 100%;
					width: auto;
					object-fit: fill;
				}

				.user-img {
					width: 32px;
					height: 32px;
					border-radius: 50%;
					overflow: hidden;
					margin: 0 auto;
				}
			}

			&.logout {
				svg {
					font-size: 2rem !important;
					position: relative;
					top: 2px;
					@media screen and (max-width: 767px) {
						font-size: 1.8rem !important;
					}
				}

				span {
					position: relative;
					top: -1px;
				}

				@media screen and (max-width: 767px) {
					position: relative;
					top: -2px;
				}
			}

			&#map,
			&#project {
			
			}

			&.user {
				cursor: default;
			}		

			span {
				font-size: 10px;
				display: block;
			}

			&.no-border {
				border-left: 1px dashed #B6AFAF;
				
			}

			&:first-child {
				border-left: 1px dashed #B6AFAF;
			}
		}
	}
}


.nav-tabs {
	li {
		padding: 10px;
		background: white;
		margin-right: 3px;
		min-width: 100px;
		margin-bottom: -1px;

		&.active {
			background-color: #515151;
			a {
				color: white;

			}
		}
		a {
			color: #444444;
			width: 100%;
			height: 100%;
		}
	}
}