.comment-container {
    width: 100%;
    max-height: 300px;
    overflow: auto;
}

.chart-loader {
    padding: 6px;
    border: 4px solid #c5c5c5;
    border-top: 4px solid #FF6384;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    animation: spin 2s linear infinite;
    display: inline-flex;
    top: 4px;
    position: relative;
}

.doughnut-loader {
    position: relative;
    margin: 35px auto 0px auto;
    padding: 20px 10px 10px 10px;
    border: 26px solid #e9e9e9;
    border-top: 26px solid #FFD573;
    border-radius: 50%;
    width: 175px;
    height: 175px;
    animation: spin 2s linear infinite;
}

.consumption-loader {
    display: -webkit-inline-box;
    display: inline-block;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    padding: 4px;
    border: 3px solid #ffe6a9;
    border-top: 3px solid #212529;
    border-radius: 50%;
    animation: spin 2s linear infinite;
}

.loader {
    padding:10px;
    border: 8px solid #f3f3f3;
    border-top: 8px solid #6c757d;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }

.loader-container {
    margin: auto;
    padding: 50px;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
