.detailed-container-dashboard {
  padding: 0 0 10px 0 !important;

  @media screen and (max-width: 767px) {
    padding-top: 20px !important;
  }

  .expanded-content {
    margin-left: 0;
    margin-right: 0;
    margin-top: 5px;

    &.chart-wrapper {
      max-width: 1500px;
      margin: auto;

      .nav {
        .nav-item {
          font-size: 18px;
        }
      }
    }
  }

  .accordion {
    .card {
      border: 0;
      margin-bottom: 0;
    }

    .card-header {
      border: 0;
      align-items: center;

      i.icon {
        margin-left: auto;
        cursor: pointer;
      }
    }

    .collapse {
      .card-header {
        box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
        border-top: 1px solid #dfdfdf;
        font-weight: 600;

        i.icon {
          position: relative;
          right: -4px;
        }
      }
    }
  }

  // Dust, temp, hummadity
  .particle-details {
    background-color: #fafafa;
    border: 1px solid #e2e2e2;
    border-top: 0;

    .particle-details-data {
      border-right: 1px solid #e2e2e2;
      padding-bottom: 20px;
      padding-right: 0;

      @media screen and (max-width: 767px) {
        padding: 0;
      }

      h4 {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
        text-align: center;
      }

      .data-current {
        text-align: center;
        padding: 40px 15px;
        border-bottom: 1px solid #e2e2e2;

        p {
          margin: 0;
          padding: 20px 0;
          font-size: 24px;
          font-weight: 700;
        }
      }

      .data-minmax {
        padding: 40px 15px;
        border-bottom: 1px solid #e2e2e2;
        text-align: center;

        @media screen and (max-width: 767px) {
          .row > div:last-of-type {
            padding-top: 20px;
          }
        }

        p {
          margin: 0;
          padding: 20px 0;
          font-size: 24px;
          font-weight: 700;
        }

        .data-min {
          .icon {
            display: block;
            position: relative;
            &:after {
              top: 0;
              bottom: 50%;
              left: 0;
              right: 0;
              background-color: #fafafa;
              content: "";
              position: absolute;
            }
          }
        }
      }

      .data-map {
        padding: 40px 15px;
        text-align: center;

        @media screen and (max-width: 767px) {
          border-bottom: 1px solid #e2e2e2;
        }

        .icon {
          svg {
            color: #c2c2c2;
          }
        }

        h4 {
          max-width: 240px;
          margin: 20px auto 0 auto;
        }
      }
    }

    .particle-details-chart {
      padding-top: 25px;

      .row {
        height: auto;
        padding: 0 20px 40px 20px;
        width: auto;
        margin-right: 0;

        & + .row {
          padding-top: 25px;
          border-top: 1px solid #e2e2e2;
        }

        p {
          font-size: 20px;
          font-weight: 600;
          padding-bottom: 10px;
        }
      }
    }

    &.particle-details-dust {
      .icon {
        svg {
          color: #ca8dee;
        }
      }
    }

    &.particle-details-temp {
      .icon {
        svg {
          color: #eeca8d;
        }
      }
    }

    &.particle-details-humadity {
      .icon {
        svg {
          color: #6b8db6;
        }
      }
    }
  }

  .nav-tabs {
    .nav-link {
      font-weight: 600;

      &.active {
        background-color: #fafafa;
        border-bottom: 0;
      }

      //   &:last-of-type{
      //       margin-left: auto;
      //       color: #000;
      //       font-size: 15px;
      //       font-weight: 500;
      //   }
    }
  }

  // lights, wifi
  .detailed-lights-body,
  .detailed-wifi-body {
    background-color: #fafafa;
    padding: 0 10px;
    border: 1px solid #e2e2e2;
    border-top: 0;

    .consumption-value {
      margin: 0;
      padding: 20px 0;
      font-size: 24px;
      font-weight: 700;
    }

    .detailed-lights-data,
    .detailed-wifi-data {
      border-right: 1px solid #e2e2e2;
      padding: 0;

      @media screen and (max-width: 767px) {
        border-right: 0;
      }

      .row {
        height: auto;
        margin-left: 0;
        margin-right: 0;
      }

      > .row {
        &:last-child > div {
          border-bottom: 0;
        }
      }

      h4 {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
        text-align: center;
      }

      .detailed-lights-body-lightswitch,
      .detailed-wifi-current {
        text-align: center;
        border-bottom: 1px solid #e2e2e2;
        padding: 40px 15px;

        p {
          margin: 0;
          padding: 20px 0;
          font-size: 24px;
          font-weight: 700;
        }
      }

      .detailed-lights-body-lightswitch {
        @media screen and (max-width: 767px) {
          .row div:first-of-type {
            padding-bottom: 20px;
          }
        }
      }

      .detailed-lights-data-cols,
      .detailed-wifi-data-chart {
        padding-right: 15px;
        padding-top: 40px;
        padding-bottom: 40px;
        border-bottom: 1px solid #e2e2e2;
        text-align: center;

        p {
          margin: 0;
          padding: 20px 0;
          font-size: 24px;
          font-weight: 700;

          &.data {
            margin-bottom: 0;
            padding-bottom: 0;
            font-size: 24px;
          }

          &.title {
            padding-top: 5px;
            font-size: 18px;
          }
        }
      }

      .detailed-lights-data-cols {
        @media screen and (max-width: 767px) {
          .row div:last-of-type {
            padding-top: 40px;
          }
        }
      }

      .data-map {
        padding-right: 15px;
        padding-top: 60px;
        text-align: center;

        @media screen and (max-width: 767px) {
          padding-top: 0;
        }

        .icon {
          svg {
            color: #c2c2c2;
          }
        }

        h4 {
          max-width: 240px;
          margin: 20px auto 0 auto;
        }
      }
    }

    .detailed-lights-chart,
    .detailed-wifi-chart {
      padding: 0;
      padding-top: 55px;

      @media screen and (max-width: 767px) {
        border-top: 1px solid #e2e2e2;
        padding-top: 40px;

        .row > div:not(:last-of-type) {
          padding-bottom: 40px;
        }
      }
      .row {
        height: auto;
        padding: 0 20px 40px 20px;
        width: auto;
        margin: auto;
        margin-right: 0;

        & + .row {
          padding-top: 40px;
          border-top: 1px solid #e2e2e2;
        }

        p {
          font-size: 20px;
          font-weight: 600;
          padding-bottom: 10px;
          text-align: left;
        }

        .chartinfo-doughnut {
          text-align: center;
          
          p.title {
            font-size: 19px;
            font-weight: 600;
            margin: 0;
            text-align: center;
          }

          p.data {
            margin: 0;
            padding: 20px 0 10px 0;
            font-size: 32px;
            font-weight: 700;
            text-align: center;
          }

          .button-group {
            position: absolute;
            right: 0;

            button {
              border: none;
              font-size: 18px;
              margin-left: 5px;
              margin-right: 5px;
              border-radius: 10px;
            }

            button:hover {
              background-color: #515151;
              color: white;
            }
          }
        }
      }
    }
  }

  .chartinfo-multiline {
    display: flex;
  }

  .button-group {
    position: absolute;
    right: 0;

    button {
      border: none;
      font-size: 18px;
      margin-left: 5px;
      margin-right: 5px;
      border-radius: 10px;
    }

    button:hover {
      background-color: #515151;
      color: white;
    }
  }

  // Owlet

  .detailed-owlet-body {
    background-color: #fafafa;
    border: 1px solid #e2e2e2;
    border-top: 0;
    padding: 0 10px;

    .row {
      height: auto !important;
    }

    .owlet-details-left {
      border-right: 1px solid #e2e2e2;
      padding: 0;
      padding-bottom: 20px;

      @media screen and (max-width: 767px) {
        border-right: 0;
      }

      .owlet-details-chart-container {
        padding: 20px;
        margin: auto;

        p {
          font-size: 20px;
          font-weight: 600;
          padding-bottom: 10px;
        }

        .tab-content-wrapper {
          padding-right: 0;

          @media screen and (max-width: 767px) {
            padding: 0;
          }
        }

        .tab-buttons-wrapper {
          padding-left: 0;

          @media screen and (max-width: 767px) {
            padding: 0;
          }

          .card-header-pills {
            margin: 0;
          }

          .nav-item {
            &:first-of-type {
              @media screen and (max-width: 767px) {
                padding-top: 5px;
              }
            }
            a {
              color: #212529;
              font-weight: 700;
              font-size: 16px;
              text-align: center;
              background-color: #e7e7e7;
              border-radius: 0;
              display: block;
              padding: 15px 0;
              transition: 300ms all ease 0s;

              &:hover {
                background-color: #666;
                color: white;
              }

              &.active {
                background-color: #ff6384;
                color: white;
                margin-left: -6px;
              }
            }

            & + .nav-item {
              margin-top: 4px;
            }
          }
        }

        .tab-content {
          padding: 25px;
          background-color: white;

          @media screen and (max-width: 767px) {
            padding: 0;
            padding-bottom: 25px;
            margin-top: 10px;
          }
        }
      }

      .owlet-details-notifications-container {
        .owlet-details-notifications {
          border-top: 1px solid #e2e2e2;
          padding: 20px 40px;

          @media screen and (max-width: 767px) {
            padding: 0 20px;
            padding-top: 40px;
          }

          p.title {
            font-size: 20px;
            font-weight: 600;
            padding-bottom: 0;
            margin-bottom: 0;
          }

          .subtitle {
            margin-bottom: 15px;
            font-size: 16px;
            font-weight: 600;
            margin-top: 30px;

            svg {
              margin-right: 10px;
              font-size: 24px;
              position: relative;
              top: 3px;
            }
          }

          .data-container {
            background-color: white;
            max-height: 320px;
            overflow-x: hidden;
            overflow-y: scroll;

            p {
              font-size: 15px;
              font-weight: 500;
              text-align: center;
              padding: 10px 0;
            }

            .table-failures {
              .id {
                width: 70%;
                font-size: 0.7rem;
              
                @media screen and (max-width: 1120px) {
                  font-size: 0.6rem;
                }
              }
              .date {
                width: 20%;
                @media screen and (max-width: 1120px) {
                  font-size: 0.8rem;
                }
              }
              .name {
                width: 10%;

                @media screen and (max-width: 1120px) {
                  font-size: 0.7rem;
                }
              }
            }
            .table-failures-history {
              .id {
                width: 45%;
                font-size: 0.7rem;
              
                @media screen and (max-width: 1120px) {
                  font-size: 0.6rem;
                }
              }
              .date-open {
                width: 15%;

                @media screen and (max-width: 1120px) {
                  font-size: 0.7rem;
                }
              }
              .date-close {
                width: 15%;

                @media screen and (max-width: 1120px) {
                  font-size: 0.7rem;
                }
              }
              .name {
                width: 30%;
                  
                @media screen and (max-width: 1120px) {
                  font-size: 0.7rem;
                }
              }
            }

            table {
              width: 100%;
              text-align: center;

              tr {
                th {
                  background-color: #515151;
                  padding: 6px 10px;
                  text-align: center;
                  font-size: 15px;
                  font-weight: 500;
                  color: white;
                  border: 1px solid #515151; 

                  @media screen and (max-width: 767px) {
                    padding: 6px 5px;
                  }
                }
                td {
                  padding: 0px !important;
                }
              }
            }
          }
        }
      }
    }

    .owlet-details-right {
      padding: 20px 20px 20px 5px;
      > .row {
        margin: 0;
      }

      .title {
        font-size: 20px;
        font-weight: 600;
        padding-bottom: 0;
        margin-bottom: 0;

        svg {
          font-size: 22px;
          margin-right: 5px;
        }
      }

      .owlet-map-buttons {
        margin-top: 15px;

        button {
          background-color: #e4e4e4;
          color: #a4a4a4;
          border: 0;
          font-weight: 500;
          transition: 300ms all ease 0s;

          @media screen and (max-width: 767px) {
            font-size: 13px;
            padding: 2px 5px !important;
          }

          &:first-of-type {
            padding: 6px 23px;
          }

          & + button {
            margin-left: 4px;
          }

          &.active {
            background-color: white !important;
            color: #8c8c8c !important;
          }

          &:hover {
            background-color: #666;
            color: white;
          }

          &:hover,
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }

      .owlet-map {
        > div {
          border: 1px solid #e2e2e2;
          margin: 5px 0;
        }
      }

      .owlet-button {
        margin-top: 10px;
        background-color: #b9b9b9;
        border: 0;
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        transition: 300ms all ease 0s;

        &:hover {
          background-color: #666;
        }

        img {
          height: 16px;
          width: auto;
          margin: -5px 5px 0 5px;
        }
      }

      .owlet-logo {
        border-top: 1px solid #e2e2e2;
        margin: 30px -5px 0 -5px;
        width: calc(100% + 10px);
        padding-top: 40px;
        max-width: none;
        display: block;
        flex: 0 1 auto;

        .logo-box {
          width: 80%;
          margin: 0 auto;
          max-width: 280px;

          img {
            width: 100%;
            height: auto;
          }

          p {
            font-size: 15px;
            font-weight: 600;
            margin: 3px 0 0 0;
          }
        }
      }
    }
  }
}
