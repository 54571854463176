.filter-container {
    padding: 0px;
    text-align: left;
    font-size: 12px;
    background-color: white;

    @media screen and (max-width: 767px) {
        padding-top: 30px !important;
        max-height: 100px;
        overflow-y: scroll;
    }
}

.filter-container p {
    margin:0;
    border-radius: 0px;
    margin: 0;
    background-color: #fbfbfb;
    padding: 5px;
    border: 1px solid #e9e9e9;
    color: #a1a1a1;
}

.filter-container p {
    i {
        padding: 0;
        background-color: #efefef;
        border-radius: 50%;
        margin-right: 5px;
        width: 24px;
        height: 24px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        transition: 300ms ease 0s;
        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 15px;
        }

        &:hover {
            background-color: darken(#efefef, 15%);
            color: white;
        }
    }

    span {
        display: inline-block;
        vertical-align: middle;
        padding-left: 5px;
    }
}

.filter-container p span:first-of-type {
    font-weight: 600;
}


.project-cadastre-filter-button-container {

    margin: 15px;

    .project-cadastre-filter-button {
        background-color: #a1a1a1;
        padding: 5px;
    }

    .project-cadastre-filter-button:hover {
        background-color: #dbdbdb;
        padding: 5px;
        color: #a1a1a1 !important;
    }
}
.project-cadastre-filter-container {
    padding: 10px;

    .back-span {
        float: left !important;
    }

    .cadastre-filter-header {
        p {
            padding-top: 20px;
            font-weight: 500;
        }
    }

    nav {
        font-size: 12px;
        padding-top: 20px;
        width: 100%;
        color: black;
        overflow-x: auto;
        overflow-y: hidden;

        a {
            color: black;
        }
        .active {
            color:rgb(77, 174, 242) !important
        }
    }

    .back{
        margin-left: 5px;
    }

    .nav-tabs{
        border: none;

        .nav-item{
            border-bottom: 1px solid #dee2e6;
            margin-bottom: 0;

            &.active{
                border-bottom: none;
            }
        }
    }

    .cadastre-filter-component-container{
        border: none;
    }

    .cadastre-filters{
        .form-label{
            width: 100%;
            text-align: left;
        }
    }
}
